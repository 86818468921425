// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";

// Components
import Facets from "../partials/Facets";
import Activities from "../partials/Activities";
import ActivityDetails from "../partials/ActivityDetails";

// DIBK Design
import { Container, Header, Paper } from "dibk-design";

// Actions
import { updateSelectedProcessCategoryKey } from "../../actions/SelectedProcessCategoryKeyActions";
import { updateSelectedFacetsFromUrl } from "actions/FacetsActions";
import { updateSelectedValidDateFromUrl } from "actions/SelectedValidDateActions";
import { getProcessCategoryByKey } from "actions/ProcessCategoriesActions";

// Helpers
import { setFocusBeforeElement } from "helpers/guiHelpers";

// Stylesheets
import style from "./ProcessCategory.module.scss";

class ProcessCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            facetExpanded: false
        };
    }

    componentDidMount() {
        this.props.updateSelectedProcessCategoryKey(this.props.match.params.processCategoryKey);
        setFocusBeforeElement(document.getElementById("root"));
    }

    componentDidUpdate(prevProps) {
        const processCategoryKeyHasChanged =
            prevProps.match.params.processCategoryKey !== this.props.match.params.processCategoryKey;
        if (processCategoryKeyHasChanged) {
            this.props.updateSelectedProcessCategoryKey(this.props.match.params.processCategoryKey);
        }
        const hadFacets = prevProps.length && prevProps.facets;
        const hasFacets = this.props.facets && this.props.facets.length;
        if (!hadFacets && hasFacets) {
            const availableFacets = {};

            this.props.facets.forEach((facet) => {
                if (availableFacets[facet.modelName]) {
                    availableFacets[facet.modelName].filters = {
                        ...availableFacets[facet.modelName].filters,
                        ...facet.filters
                    };
                } else {
                    availableFacets[facet.modelName] = { filters: facet.filters };
                }
            });

            this.props.updateSelectedFacetsFromUrl(availableFacets);
        }
        this.props.updateSelectedValidDateFromUrl();

        const hasSelectedActivity = !!this.props.match.params.activityId;
        if (hasSelectedActivity) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }

    renderActivityDetails(processCategoryKey, activityId) {
        return (
            activityId && (
                <ActivityDetails
                    processCategoryKey={processCategoryKey}
                    activityId={activityId}
                    sidebarView={true}
                    history={this.props.history}
                />
            )
        );
    }
    toogleFacetsClick() {
        this.setState({
            facetExpanded: !this.state.facetExpanded
        });
    }

    render() {
        const processCategoryKey = this.props.match.params.processCategoryKey;
        const activityId = this.props.match.params.activityId;
        const selectedProcessCategory = this.props.getProcessCategoryByKey(processCategoryKey);

        return (
            <Container maxWidth="1320px">
                <div className={style.hidden}>
                    <Header
                        content={
                            selectedProcessCategory && selectedProcessCategory.name
                                ? selectedProcessCategory.name
                                : processCategoryKey
                        }
                    />
                </div>

                <div className={style.mainContainer}>
                    <button className={style.expandButton} onClick={() => this.toogleFacetsClick()}>
                        {this.state.facetExpanded ? "Skjul filter" : "Vis filter"}
                    </button>
                    <div className={`${this.state.facetExpanded ? style.expanded : ""} ${style.filterContainer}`}>
                        <Facets />
                    </div>
                    <div
                        id="main-content"
                        className={`${this.state.facetExpanded ? style.expanded : ""} ${style.checklistContainer}`}
                    >
                        <Paper noMargin>
                            <Header
                                content={
                                    selectedProcessCategory && selectedProcessCategory.name
                                        ? selectedProcessCategory.name
                                        : processCategoryKey
                                }
                                htmlTag="p"
                            />
                            {!!selectedProcessCategory?.description?.length && (
                                <div className={style.description}>{selectedProcessCategory.description}</div>
                            )}
                            <Activities processCategoryKey={processCategoryKey} />
                        </Paper>
                    </div>
                    {this.renderActivityDetails(processCategoryKey, activityId)}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    location: state.router.location,
    processCategories: state.processCategories,
    facets: state.facets
});

const mapDispatchToProps = {
    updateSelectedProcessCategoryKey,
    getProcessCategoryByKey,
    updateSelectedFacetsFromUrl,
    updateSelectedValidDateFromUrl
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessCategory);
