// Dependencies
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// DIBK Design
import { Header, Button, Lead, Container } from "dibk-design";

// Helpers
import { classNameArrayToClassNameString } from "helpers/guiHelpers";

// Stylesheets
import wizardStyle from "components/routes/Wizard.module.scss";

const PlanbestemmelserHomeContent = ({ headerContent, checklistApiUrl }) => {
    const listStyle = {
        listStyleType: "none",
        padding: "0",
    };

    return (
        <Fragment>
            <Header label="Veiviser" size={1}>
                {headerContent}
            </Header>
            <div className={classNameArrayToClassNameString([wizardStyle.buttonRow, wizardStyle.marginBottom])}>
                <Button type="button" color="primary">
                    <Link to="/checklist/planbestemmelseskatalog/">Gå til katalog</Link>
                </Button>
            </div>
            <p>
                <b>Versjon 2 – revidert februar 2025</b>
            </p>
            <p>
                <b>Inneholder kvalitetssjekkede og anbefalte reguleringsplanbestemmelser.</b>
            </p>
            <p>
                Den nasjonale planbestemmelseskatalogen inneholder forslag til lovlige, enkle og tydelige planbestemmelser som anbefales brukt av Kommunal- og distriktsdepartementet så langt som mulig. Katalogen viser anbefalt struktur og skrivemåte på reguleringsplanbestemmelser for å få disse til å fungere i digitale arealplanprosesser. Det anbefales at katalogen brukes så langt det er mulig når nye reguleringsplaner skal lages.
            </p>
            <Container border>
            <div style={{ border: "1px solid #ccc", padding: "10px", margin: "10px 0" }}>
                <p>
                    Departementet har ikke noe ansvar for hvordan katalogen eller de konkrete eksemplene brukes og har i denne versjonen ikke gjennomført en vurdering av eksemplenes juridiske holdbarhet i forhold til plan- og bygningsloven. En ønsker i denne omgang å høste erfaring og få gode innspill / forslag. Spesielt eksempler som ikke er i tråd med lov og forskrift er viktig å identifisere og gi tilbakemelding på.
                </p>
                </div>
            </Container>
            <p>
                De enkelte forslagene må sees på som eksempler og utgangspunkt for å lage gode planbestemmelser og bidra til at like forutsetninger defineres likt. Det betyr at de konkrete bestemmelsene i en reguleringsplan ofte vil ha variasjoner og kan være sammensatt av flere av de gitte eksemplene.
            </p>
            <p>
                Planbestemmelseskatalogen vil sammen med veilederen til <a href="https://www.regjeringen.no/no/dokumenter/reguleringsplan/id2928063/">reguleringsplan</a> og veilederen til forskrift om kart, stedfestet informasjon, arealformål og digitalt planregister gi informasjon om hvordan reguleringsplaner kan utarbeides etter bestemmelsene i plan- og bygningsloven.
            </p>
            <p>
                Departementet har utarbeidet en mal for reguleringsplanbestemmelser som anbefales brukt sammen med planbestemmelseskatalogen.
            </p>
            <p>
                Planbestemmelseskatalogen er utarbeidet med utgangspunkt i ulike eksempelsamlinger for reguleringsplanbestemmelser og fra en rekke vedtatte reguleringsplaners bestemmelser. Disse er samlet og analysert via "Planslurpen" - en KI-algoritme utviklet til "Drømmeplan" prosjektene – som er vurdert og bearbeidet av en arbeidsgruppe bestående av fagpersoner på planområdet.
            </p>
            <p>
                Samlingen i katalogen er en videreføring av første versjon, publisert i januar 2024. Eksemplene er faglig vurdert – men nye innspill til faglig innhold, juridisk holdbarhet og klart språk tas imot med takk. Departementet vil fortløpende utvide samlingen med flere eksempler, basert på innspill og forslag.
            </p>
            <Button type="button" color="secondary">
                <a href="mailto:hilde-johansen.bakken@kdd.dep.no">Gi innspill</a>
            </Button>
            
            <Lead>
                <Header content="Reguleringsplanbestemmelsene kan også brukes via vårt API" size={3} />
                <a href={checklistApiUrl}>Api for reguleringsplanbestemmelser</a>
            </Lead>
        </Fragment>
    );
};

export default PlanbestemmelserHomeContent;
